.card-container {
  display: grid;
  perspective: 700px;
}

.card-flip {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas: "frontAndBack";
  transform-style: preserve-3d;
  transition: all 0.7s ease;
}

.card-flip div {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.front {
  grid-area: frontAndBack;
}

.front h3 {
  font-weight: 600;
  color: rgb(1, 34, 55);
}

.back {
  grid-area: frontAndBack;
  transform: rotateY(-180deg);
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../../../assets/wave.svg);
  height: 90vh;
}

.back h3 {
  text-align: center;
  padding: 20px;
  color: rgb(1, 34, 55);
  font-size: 30px;
}

.back h3 {
  text-align: center;
  padding: 20px;
  color: rgb(1, 34, 55);
  font-size: 30px;
}

.back p {
  color: #111;
}

.back button {
  margin-top: 20px;
}
.card-container:hover .card-flip {
  transform: rotateY(180deg);
}

.single {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminMsgTxt {
  font-size: 18px;
  color: #111;
  text-align: justify;
}