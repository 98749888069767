/*------------------------------------
 - COLOR custom
 ------------------------------------*/
.alert-custom {
  color: #1b1b36;
  background-color: #bbbbdd;
  border-color: #adadd6;
}
.alert-custom hr {
  border-top-color: #9c9ccd;
}
.alert-custom .alert-link {
  color: #0a0a14;
}
.badge-custom {
  color: #fff;
  background-color: #444488;
}
.badge-custom[href]:hover,
.badge-custom[href]:focus {
  color: #fff;
  background-color: #333366;
}
.bg-custom {
  background-color: #444488 !important;
}
a.bg-custom:hover,
a.bg-custom:focus,
button.bg-custom:hover,
button.bg-custom:focus {
  background-color: #333366 !important;
}
.border-custom {
  border-color: #444488 !important;
}
.btn-custom {
  color: #fff;
  background-color: #444488;
  border-color: #444488;
}
.btn-custom:hover {
  color: #fff;
  background-color: #383870;
  border-color: #333366;
}
.btn-custom:focus,
.btn-custom.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 68, 136, 0.5);
}
.btn-custom.disabled,
.btn-custom:disabled {
  color: #fff;
  background-color: #444488;
  border-color: #444488;
}
.btn-custom:not(:disabled):not(.disabled):active,
.btn-custom:not(:disabled):not(.disabled).active,
.show > .btn-custom.dropdown-toggle {
  color: #fff;
  background-color: #333366;
  border-color: #2d2d5b;
}
.btn-custom:not(:disabled):not(.disabled):active:focus,
.btn-custom:not(:disabled):not(.disabled).active:focus,
.show > .btn-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 68, 136, 0.5);
}
.btn-outline-custom {
  color: #444488;
  background-color: transparent;
  border-color: #444488;
}
.btn-outline-custom:hover {
  color: #fff;
  background-color: #444488;
  border-color: #444488;
}
.btn-outline-custom:focus,
.btn-outline-custom.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 68, 136, 0.5);
}
.btn-outline-custom.disabled,
.btn-outline-custom:disabled {
  color: #444488;
  background-color: transparent;
}
.btn-outline-custom:not(:disabled):not(.disabled):active,
.btn-outline-custom:not(:disabled):not(.disabled).active,
.show > .btn-outline-custom.dropdown-toggle {
  color: #fff;
  background-color: #444488;
  border-color: #444488;
}
.btn-outline-custom:not(:disabled):not(.disabled):active:focus,
.btn-outline-custom:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-custom.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 68, 136, 0.5);
}
.list-group-item-custom {
  color: #1b1b36;
  background-color: #adadd6;
}
.list-group-item-custom.list-group-item-action:hover,
.list-group-item-custom.list-group-item-action:focus {
  color: #1b1b36;
  background-color: #9c9ccd;
}
.list-group-item-custom.list-group-item-action.active {
  color: #fff;
  background-color: #1b1b36;
  border-color: #1b1b36;
}
.table-custom,
.table-custom > th,
.table-custom > td {
  background-color: #adadd6;
}
.table-hover .table-custom:hover {
  background-color: #9c9ccd;
}
.table-hover .table-custom:hover > td,
.table-hover .table-custom:hover > th {
  background-color: #9c9ccd;
}
.text-custom {
  color: #444488 !important;
}
a.text-custom:hover,
a.text-custom:focus {
  color: #333366 !important;
}
