* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* Carousel */

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Navbar */

.navbarNav {
  font-size: 15px;
}

.navbar li {
  list-style-type: none;
  text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
  color: #3459e6;
}

.navbarNav li a:hover {
  color: #111 !important;
}

.navDropDownHead {
  color: #111 !important;
  cursor: pointer;
}

.active {
  color: #111 !important;
}

.selected a {
  color: #111 !important;
  border-radius: 2px !important;
  border-bottom: 3px solid #111 !important;
}

.altSelected a {
  color: #232323 !important;
  opacity: 0.7;
}

.drop-active {
  color: #3459e6 !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

/* @media screen and (max-width: 1037px) {
  .navbarNav {
    display: none;
  }
} */

/* Carousel */

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item-active {
  display: flex;
}

.carousel img {
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  object-fit: cover;
  opacity: 0.8;
  animation: zoom-in-zoom-out 10s;
  transition: transform 0.3s ease;
}

.altCarousel img {
  height: 90vh !important;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  object-fit: cover;
  opacity: 0.8;
  animation: zoom-in-zoom-out 10s;
  transition: transform 0.3s ease;
}

.altCarousel > div > .active {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(35 35 35 / var(--tw-bg-opacity)) !important;
}

.altCarousel > div > div > div {
  bottom: 15.35rem;
}

.alt1-carousel > div > div > div {
  @media screen and (max-width: 684px) {
    bottom: 10.35rem;
  }
}

.carousel-item {
  transition: transform 0.3s ease-in-out;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  transition: opacity 0s 2.6s;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.sideMenu {
  animation: fadeInRight 2s;
}

/* Curve */
.layer {
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(./assets/wave.svg);
  height: 90vh;
}

/* Mission Card */

.missionCard {
  height: 430px;
}

.cardHeader {
  background-color: rgb(0, 148, 68);
}

.missionWhole {
  margin-bottom: 50px;
}

/* Register */

.form-control {
  border: 2px solid rgb(0, 148, 68);
}

.registerField input {
  margin-bottom: 10px;
}

.navbar {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* Gallery Video Section */
@media screen and (max-width: 450px) {
  .video-responsive iframe {
    width: 320px !important;
    height: 200px !important;
  }
}

/* Nav Bootstrap */
.nav .nav-link {
  padding: 15px 23px;
  margin: 8px 15px;
}

.nav .nav-link:hover {
  background-color: #444488;
  color: #fff;
}
.nav .nav-link.active {
  background-color: #444488 !important;
}

/* Toggler */

.switch input {
  display: none;
}
.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 8px;
  transform: translateY(50%);
  position: relative;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #777;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(30px);
  background: limeGreen;
}
input:checked + .slider {
  box-shadow: 0 0 0 2px limeGreen, 0 0 2px limeGreen;
}

.darkModeSlider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}
.darkModeSlider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #777;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.4s;
}

input:checked + .darkModeSlider:before {
  transform: translateX(30px);
  background: #fff;
}
input:checked + .darkModeSlider {
  box-shadow: 0 0 0 2px #fff, 0 0 2px #fff;
}

/* Settings Modal */

.dark-modal .modal-content {
  background-color: #303134;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  border: 2px solid #303134;
}

.dark-modal .modal-content h2 {
  color: #fff;
}

.white-modal .modal-content {
  background-color: #fff;
  color: #111;
  font-weight: 500;
  font-size: 20px;
}

.form-group label {
  font-size: 18px;
  font-weight: 500;
}

.form-group textarea {
  border: 1px solid #111;
}

.form-group input {
  border: 1px solid #111;
}

.hint {
  font-weight: 600;
  font-size: 30px;
}

.newslist {
  background: red;
}

.active {
  background-color: #fff !important;
}

.active h4 {
  color: #111 !important;
}

.active i {
  color: #111 !important;
}

.ractive {
  background-color: #111 !important;
}

.ractive h4 {
  color: #fff !important;
}

.ractive i {
  color: #fff !important;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}

#lightboxBackdrop {
  padding-top: 108px;
}

.pagination > li > a,
.pagination > li > span {
  color: #111 !important;
  background-color: #fff !important;
}

.input-group-text {
  border: 2px solid rgb(0, 148, 68) !important;
}

.careerCard {
  border-left: 4px solid rgb(0, 148, 68);
  cursor: pointer;
  margin: 23px;
}

.form-select {
  border: 2px solid rgb(0, 148, 68) !important;
}

.careerCard:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  cursor: pointer;
}

.alice-carousel {
  text-align: center;
  padding-left: 50px;
}

.img-grid {
  display: grid;
  gap: 12px;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wcarousel {
  object-position: top;
}

.tabWrapper li .nav-link {
  font-weight: 500;
}

.tabWrapper li .nav-link.active {
  color: #fff !important;
}

.card-row {
  @media screen and (max-width: 788px) {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    > div {
      width: 90%;
    }
  }
}

.card-col {
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(100% / 3);
  transition: all 0.4s ease;
  box-shadow: 0 5px 15px 0 rgba(62, 65, 159, 0.1);
  @media screen and (max-width: 996px) {
    > div {
      padding: 20px;
    }
  }
  @media screen and (max-width: 788px) {
    width: 100%;
    > div {
      padding: 20px;
    }
  }
}
